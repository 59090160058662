import { default as energy_45green_45practices1rSYoxdsUBMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyOjxVwZLuftMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45buildereArhIXlbblMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexQP6KW4iRhpMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamgIDtp4o79QMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderraqwEYJp8qMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesVWcmXtChsBMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93ZT5NGwucQOMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexUvxk5QpuKGMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexJALZvTyVsbMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_938gyhUux2fVMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexYUhGWiPIbGMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionOrWkdp1ZDjMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesKXW96anVxyMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqJGiiPRh72nMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesdvSCVp236AMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexOOEKKY91wUMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations7Zg3nmKQd6Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersO8WNu8ogY4Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexkjyBsiHvpmMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as index1rDG8eDIApMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexSGTQ1UxyzjMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index4rlvZ1q04dMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexwpTiwKISINMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexthL3JlH2q2Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexYhiKqsEV0SMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationkPpHXfJoa2Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionyG6VX7ZiZDMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placecWay0KYLXQMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceBVWF5JqmJfMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93ptjPLHSHH8Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexlgLEbtbClyMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexhOk8QmAyg3Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnesslOgF7qdOw9Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_938hyoWbKaPvMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesQHqNIaqN13Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45search3UpTMzq7HgMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouserh3ArYNZmtMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition8uDg13Yrd1Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottage71je1ieq1nMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as index2e4IYAdRoTMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsjbuBbZgZurMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usm03JgoN3ofMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermscqBDkCbKWyMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxzE6DLB2GqwMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93dg6wAVuth9Meta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubETcWPuL4HHMeta } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubETcWPuL4HH } from "/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practices1rSYoxdsUBMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practices1rSYoxdsUBMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practices1rSYoxdsUBMeta || {},
    alias: energy_45green_45practices1rSYoxdsUBMeta?.alias || [],
    redirect: energy_45green_45practices1rSYoxdsUBMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyOjxVwZLuftMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyOjxVwZLuftMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyOjxVwZLuftMeta || {},
    alias: fun_45part_45journeyOjxVwZLuftMeta?.alias || [],
    redirect: fun_45part_45journeyOjxVwZLuftMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45buildereArhIXlbblMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45buildereArhIXlbblMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45buildereArhIXlbblMeta || {},
    alias: homesafe_45colorado_45master_45buildereArhIXlbblMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45buildereArhIXlbblMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexQP6KW4iRhpMeta?.name ?? "about-us",
    path: indexQP6KW4iRhpMeta?.path ?? "/about-us",
    meta: indexQP6KW4iRhpMeta || {},
    alias: indexQP6KW4iRhpMeta?.alias || [],
    redirect: indexQP6KW4iRhpMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamgIDtp4o79QMeta?.name ?? "about-us-our-team",
    path: our_45teamgIDtp4o79QMeta?.path ?? "/about-us/our-team",
    meta: our_45teamgIDtp4o79QMeta || {},
    alias: our_45teamgIDtp4o79QMeta?.alias || [],
    redirect: our_45teamgIDtp4o79QMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderraqwEYJp8qMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderraqwEYJp8qMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderraqwEYJp8qMeta || {},
    alias: preferred_45lenderraqwEYJp8qMeta?.alias || [],
    redirect: preferred_45lenderraqwEYJp8qMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesVWcmXtChsBMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesVWcmXtChsBMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesVWcmXtChsBMeta || {},
    alias: realtor_45resourcesVWcmXtChsBMeta?.alias || [],
    redirect: realtor_45resourcesVWcmXtChsBMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93ZT5NGwucQOMeta?.name ?? "blog-post",
    path: _91post_93ZT5NGwucQOMeta?.path ?? "/blog/:post()",
    meta: _91post_93ZT5NGwucQOMeta || {},
    alias: _91post_93ZT5NGwucQOMeta?.alias || [],
    redirect: _91post_93ZT5NGwucQOMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexUvxk5QpuKGMeta?.name ?? "blog-category-category",
    path: indexUvxk5QpuKGMeta?.path ?? "/blog/category/:category()",
    meta: indexUvxk5QpuKGMeta || {},
    alias: indexUvxk5QpuKGMeta?.alias || [],
    redirect: indexUvxk5QpuKGMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJALZvTyVsbMeta?.name ?? "blog",
    path: indexJALZvTyVsbMeta?.path ?? "/blog",
    meta: indexJALZvTyVsbMeta || {},
    alias: indexJALZvTyVsbMeta?.alias || [],
    redirect: indexJALZvTyVsbMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938gyhUux2fVMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_938gyhUux2fVMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_938gyhUux2fVMeta || {},
    alias: _91_46_46_46slug_938gyhUux2fVMeta?.alias || [],
    redirect: _91_46_46_46slug_938gyhUux2fVMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYUhGWiPIbGMeta?.name ?? "campaigns",
    path: indexYUhGWiPIbGMeta?.path ?? "/campaigns",
    meta: indexYUhGWiPIbGMeta || {},
    alias: indexYUhGWiPIbGMeta?.alias || [],
    redirect: indexYUhGWiPIbGMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionOrWkdp1ZDjMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionOrWkdp1ZDjMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionOrWkdp1ZDjMeta || {},
    alias: ask_45a_45questionOrWkdp1ZDjMeta?.alias || [],
    redirect: ask_45a_45questionOrWkdp1ZDjMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesKXW96anVxyMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesKXW96anVxyMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesKXW96anVxyMeta || {},
    alias: career_45opportunitiesKXW96anVxyMeta?.alias || [],
    redirect: career_45opportunitiesKXW96anVxyMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqJGiiPRh72nMeta?.name ?? "contact-us-faq",
    path: faqJGiiPRh72nMeta?.path ?? "/contact-us/faq",
    meta: faqJGiiPRh72nMeta || {},
    alias: faqJGiiPRh72nMeta?.alias || [],
    redirect: faqJGiiPRh72nMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesdvSCVp236AMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesdvSCVp236AMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesdvSCVp236AMeta || {},
    alias: homeowner_45resourcesdvSCVp236AMeta?.alias || [],
    redirect: homeowner_45resourcesdvSCVp236AMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexOOEKKY91wUMeta?.name ?? "contact-us",
    path: indexOOEKKY91wUMeta?.path ?? "/contact-us",
    meta: indexOOEKKY91wUMeta || {},
    alias: indexOOEKKY91wUMeta?.alias || [],
    redirect: indexOOEKKY91wUMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locations7Zg3nmKQd6Meta?.name ?? "contact-us-our-locations",
    path: our_45locations7Zg3nmKQd6Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locations7Zg3nmKQd6Meta || {},
    alias: our_45locations7Zg3nmKQd6Meta?.alias || [],
    redirect: our_45locations7Zg3nmKQd6Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersO8WNu8ogY4Meta?.name ?? "current-offers",
    path: current_45offersO8WNu8ogY4Meta?.path ?? "/current-offers",
    meta: current_45offersO8WNu8ogY4Meta || {},
    alias: current_45offersO8WNu8ogY4Meta?.alias || [],
    redirect: current_45offersO8WNu8ogY4Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexkjyBsiHvpmMeta?.name ?? "events-event",
    path: indexkjyBsiHvpmMeta?.path ?? "/events/:event()",
    meta: indexkjyBsiHvpmMeta || {},
    alias: indexkjyBsiHvpmMeta?.alias || [],
    redirect: indexkjyBsiHvpmMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: index1rDG8eDIApMeta?.name ?? "events",
    path: index1rDG8eDIApMeta?.path ?? "/events",
    meta: index1rDG8eDIApMeta || {},
    alias: index1rDG8eDIApMeta?.alias || [],
    redirect: index1rDG8eDIApMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexSGTQ1UxyzjMeta?.name ?? "homes-location-community",
    path: indexSGTQ1UxyzjMeta?.path ?? "/homes/:location()/:community()",
    meta: indexSGTQ1UxyzjMeta || {},
    alias: indexSGTQ1UxyzjMeta?.alias || [],
    redirect: indexSGTQ1UxyzjMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index4rlvZ1q04dMeta?.name ?? "homes-location-community-models-model",
    path: index4rlvZ1q04dMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index4rlvZ1q04dMeta || {},
    alias: index4rlvZ1q04dMeta?.alias || [],
    redirect: index4rlvZ1q04dMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwpTiwKISINMeta?.name ?? "homes-location-community-plans-plan",
    path: indexwpTiwKISINMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexwpTiwKISINMeta || {},
    alias: indexwpTiwKISINMeta?.alias || [],
    redirect: indexwpTiwKISINMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexthL3JlH2q2Meta?.name ?? "homes-location-community-qmi-home",
    path: indexthL3JlH2q2Meta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexthL3JlH2q2Meta || {},
    alias: indexthL3JlH2q2Meta?.alias || [],
    redirect: indexthL3JlH2q2Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYhiKqsEV0SMeta?.name ?? "index",
    path: indexYhiKqsEV0SMeta?.path ?? "/",
    meta: indexYhiKqsEV0SMeta || {},
    alias: indexYhiKqsEV0SMeta?.alias || [],
    redirect: indexYhiKqsEV0SMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationkPpHXfJoa2Meta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationkPpHXfJoa2Meta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationkPpHXfJoa2Meta || {},
    alias: knolls_45hoa_45informationkPpHXfJoa2Meta?.alias || [],
    redirect: knolls_45hoa_45informationkPpHXfJoa2Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionyG6VX7ZiZDMeta?.name ?? "land-acquisition",
    path: land_45acquisitionyG6VX7ZiZDMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionyG6VX7ZiZDMeta || {},
    alias: land_45acquisitionyG6VX7ZiZDMeta?.alias || [],
    redirect: land_45acquisitionyG6VX7ZiZDMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placecWay0KYLXQMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placecWay0KYLXQMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placecWay0KYLXQMeta || {},
    alias: brents_45placecWay0KYLXQMeta?.alias || [],
    redirect: brents_45placecWay0KYLXQMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceBVWF5JqmJfMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceBVWF5JqmJfMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceBVWF5JqmJfMeta || {},
    alias: experience_45lower_45maintenanceBVWF5JqmJfMeta?.alias || [],
    redirect: experience_45lower_45maintenanceBVWF5JqmJfMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93ptjPLHSHH8Meta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93ptjPLHSHH8Meta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93ptjPLHSHH8Meta || {},
    alias: _91testimonial_93ptjPLHSHH8Meta?.alias || [],
    redirect: _91testimonial_93ptjPLHSHH8Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexlgLEbtbClyMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexlgLEbtbClyMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexlgLEbtbClyMeta || {},
    alias: indexlgLEbtbClyMeta?.alias || [],
    redirect: indexlgLEbtbClyMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhOk8QmAyg3Meta?.name ?? "lifestyle-reviews",
    path: indexhOk8QmAyg3Meta?.path ?? "/lifestyle/reviews",
    meta: indexhOk8QmAyg3Meta || {},
    alias: indexhOk8QmAyg3Meta?.alias || [],
    redirect: indexhOk8QmAyg3Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnesslOgF7qdOw9Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnesslOgF7qdOw9Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnesslOgF7qdOw9Meta || {},
    alias: whats_45lifefullnesslOgF7qdOw9Meta?.alias || [],
    redirect: whats_45lifefullnesslOgF7qdOw9Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938hyoWbKaPvMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_938hyoWbKaPvMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_938hyoWbKaPvMeta || {},
    alias: _91_46_46_46slug_938hyoWbKaPvMeta?.alias || [],
    redirect: _91_46_46_46slug_938hyoWbKaPvMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesQHqNIaqN13Meta?.name ?? "my-favorites",
    path: my_45favoritesQHqNIaqN13Meta?.path ?? "/my-favorites",
    meta: my_45favoritesQHqNIaqN13Meta || {},
    alias: my_45favoritesQHqNIaqN13Meta?.alias || [],
    redirect: my_45favoritesQHqNIaqN13Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45search3UpTMzq7HgMeta?.name ?? "new-home-search",
    path: new_45home_45search3UpTMzq7HgMeta?.path ?? "/new-home-search",
    meta: new_45home_45search3UpTMzq7HgMeta || {},
    alias: new_45home_45search3UpTMzq7HgMeta?.alias || [],
    redirect: new_45home_45search3UpTMzq7HgMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouserh3ArYNZmtMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouserh3ArYNZmtMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouserh3ArYNZmtMeta || {},
    alias: easyhouserh3ArYNZmtMeta?.alias || [],
    redirect: easyhouserh3ArYNZmtMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition8uDg13Yrd1Meta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition8uDg13Yrd1Meta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition8uDg13Yrd1Meta || {},
    alias: limitededition8uDg13Yrd1Meta?.alias || [],
    redirect: limitededition8uDg13Yrd1Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottage71je1ieq1nMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottage71je1ieq1nMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottage71je1ieq1nMeta || {},
    alias: wee_45cottage71je1ieq1nMeta?.alias || [],
    redirect: wee_45cottage71je1ieq1nMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: index2e4IYAdRoTMeta?.name ?? "our-homes",
    path: index2e4IYAdRoTMeta?.path ?? "/our-homes",
    meta: index2e4IYAdRoTMeta || {},
    alias: index2e4IYAdRoTMeta?.alias || [],
    redirect: index2e4IYAdRoTMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsjbuBbZgZurMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsjbuBbZgZurMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsjbuBbZgZurMeta || {},
    alias: our_45neighborhoodsjbuBbZgZurMeta?.alias || [],
    redirect: our_45neighborhoodsjbuBbZgZurMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usm03JgoN3ofMeta?.name ?? "partner-with-us",
    path: partner_45with_45usm03JgoN3ofMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usm03JgoN3ofMeta || {},
    alias: partner_45with_45usm03JgoN3ofMeta?.alias || [],
    redirect: partner_45with_45usm03JgoN3ofMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermscqBDkCbKWyMeta?.name ?? "privacyterms",
    path: privacytermscqBDkCbKWyMeta?.path ?? "/privacyterms",
    meta: privacytermscqBDkCbKWyMeta || {},
    alias: privacytermscqBDkCbKWyMeta?.alias || [],
    redirect: privacytermscqBDkCbKWyMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxzE6DLB2GqwMeta?.name ?? "sandbox",
    path: sandboxzE6DLB2GqwMeta?.path ?? "/sandbox",
    meta: sandboxzE6DLB2GqwMeta || {},
    alias: sandboxzE6DLB2GqwMeta?.alias || [],
    redirect: sandboxzE6DLB2GqwMeta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93dg6wAVuth9Meta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93dg6wAVuth9Meta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93dg6wAVuth9Meta || {},
    alias: _91_46_46_46slug_93dg6wAVuth9Meta?.alias || [],
    redirect: _91_46_46_46slug_93dg6wAVuth9Meta?.redirect,
    component: () => import("/codebuild/output/src367477592/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/build-with-us/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/career-opportunities/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/email-signup/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/get-updates/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/lifestyle/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/login/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/lost-password/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/maintenance-items/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/organism-page/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/register/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/reset-password/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/style-guide/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  },
  {
    name: component_45stubETcWPuL4HHMeta?.name ?? undefined,
    path: component_45stubETcWPuL4HHMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubETcWPuL4HHMeta || {},
    alias: component_45stubETcWPuL4HHMeta?.alias || [],
    redirect: component_45stubETcWPuL4HHMeta?.redirect,
    component: component_45stubETcWPuL4HH
  }
]